import { signInWithEmailAndPassword } from 'firebase/auth'
import React, { useState } from 'react'
import { auth } from '../../firebase/firebase'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';



const Login = () => {

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {

            const user =  await signInWithEmailAndPassword(auth,email,password)
            localStorage.setItem("accesstoken" , user.user.accessToken)
            localStorage.setItem("userId" , user.user.uid)
            toast.success("User logged in successfully..", { position: 'top-center' })
            setTimeout(() => {
                navigate("/dashboard");
            }, 1000);
            // localStorage.setItem("id", 0)
        } catch (error) {
            console.log("error", error)
            toast.error(error.message, {
                position: 'bottom-center'
            })
        }
    }

    return (

        <main className="container d-flex flex-column">
            <div className="row align-items-center justify-content-center g-0 min-vh-100">
                <div className="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">
                    
                    {/* Card */}
                    <div className="card smooth-shadow-md">
                        {/* Card body */}
                        <div className="card-body p-6">
                            <div className="mb-4">
                                <a href="javascript:void(0);">
                                    <img
                                        src="/assets/img/logo.jpg"
                                        className="mb-2 text-inverse h-50 w-50"
                                        alt="Image"
                                    />
                                </a>
                                <h6 className="mb-6">Please enter your user information.</h6>
                            </div>
                            {/* Form */}
                            <form id="loginFrom" onSubmit={handleSubmit}>
                                <div
                                    id="alert-message"
                                    className="d-flex justify-content-center mb-3"
                                />
                                {/* Username */}
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label login-fields">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        name="email"
                                        placeholder="Email address here"
                                        required=""
                                    />
                                </div>
                                {/* Password */}
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label login-fields">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        id="password"
                                        className="form-control"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        name="password"
                                        placeholder="**************"
                                        required=""
                                        minLength={6}
                                    />
                                </div>
                                {/* Checkbox */}
                                <div className="d-lg-flex justify-content-between align-items-center mb-4">
                                    <div className="form-check custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="rememberme"
                                        />
                                        <label className="form-check-label" htmlFor="rememberme">
                                            Remember me
                                        </label>
                                    </div>
                                        <div>
                                            <p href="/forgot-password" className="text-inherit text-muted ">
                                                Forgot your password?
                                            </p>
                                        </div>
                                </div>
                                <div>
                                    {/* Button */}
                                    <div className="d-grid">
                                        <button type="submit" className="btn btn-primary">
                                            Sign in
                                        </button>
                                    </div>
                                    <div className="d-md-flex justify-content-between mt-4">
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    )
}

export default Login