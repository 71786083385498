import { BrowserRouter as Router, Route, Routes, BrowserRouter } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import './App.css';
import Login from "./components/login/Login";
import { ToastContainer } from "react-toastify";
import MyAvailability from "./components/MyAvailability/MyAvailability";
import MyAppointments from "./components/MyAppointments/MyAppointments";
import AcceptedDetail from "./components/MyAppointments/AcceptedDetail";
import PendingDetail from "./components/MyAppointments/PendingDetail";
import FinishedDetail from "./components/MyAppointments/FinishedDetail";
import Chat from "./components/MyAppointments/Chat";
import Profile from "./components/Profile/Profile";
import EditProfile from "./components/Profile/EditProfile";
import PrivateRoute from "./components/AuthProvider/PrivateRoute";
import MyPatients from "./components/MyPatients/MyPatients";
import PatientAppointments from "./components/MyPatients/PatientAppointments";


function App() {



  // useEffect(() => {


  // } ,[])



  return (

    <main className="main-wrapper"  >
      <BrowserRouter basename="/">
        {/* <Header /> */}

        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route
            element={
              <PrivateRoute
              // userDetail={userDetail}
              // onProjectChange={handleProjectChange}
              // project={projectId}
              />
            }
          >
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/myavailablity" element={<MyAvailability />} />
            <Route exact path="/myappointments" element={<MyAppointments />} />
            <Route exact path="/appointmentAccepted" element={<AcceptedDetail />} />
            <Route exact path="/appointmentPending" element={<PendingDetail />} />
            <Route exact path="/appointmentFinished" element={<FinishedDetail />} />
            <Route exact path="/chat" element={<Chat />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/editprofile" element={<EditProfile />} />
            <Route exact path="/mypatients" element={<MyPatients />} />
            <Route exact path="/patientAppointments" element={<PatientAppointments />} />
          </Route>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </main>
  );
}

export default App;
