import React from 'react'
import Sidebar from '../Partials/Sidebar/Sidebar'
import { Link, useNavigate } from 'react-router-dom'

const PatientAppointments = () => {

    const localHandler = () => {
        localStorage.setItem("patients", 1)
        window.open('/chat', '_blank');
    }

    return (
        <>
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        My Patients
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">My Patients</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className="container-fluid ">
                    <div className='row'>
                        <Sidebar />
                        <div className="col-md-7 col-lg-8 col-xl-9 dct-appoinment">
                            <div className="card">
                                <div className="card-body pt-0">
                                    <div className="user-tabs">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified flex-wrap">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    href="#pat_appointments"
                                                    data-toggle="tab"
                                                >
                                                    Appointments
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        {/* Appointment Tab */}
                                        <div id="pat_appointments" className="tab-pane fade show active">
                                            <div className="card card-table mb-0">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-center mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Doctor</th>
                                                                    <th>Appt Date</th>
                                                                    <th>Booking Date</th>
                                                                    <th>Email</th>
                                                                    <th>Contact</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a
                                                                                href="doctor-profile.html"
                                                                                className="avatar avatar-sm mr-2"
                                                                            >
                                                                                <img
                                                                                    className="avatar-img rounded-circle"
                                                                                    src="assets/img/doctors/doctor-thumb-02.jpg"
                                                                                    alt="User Image"
                                                                                />
                                                                            </a>
                                                                            <a href="doctor-profile.html">
                                                                                Dr. Sandhya Chinala <span>Psychiatrist</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        14 Nov 2019{" "}
                                                                        <span className="d-block text-info">10.00 AM</span>
                                                                    </td>
                                                                    <td>12 Nov 2019</td>
                                                                    <td>Richard@gmail.com</td>
                                                                    <td>54656251</td>
                                                                    <td>
                                                                        <span className="badge badge-pill bg-success-light">
                                                                            Finished
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a
                                                                                href="doctor-profile.html"
                                                                                className="avatar avatar-sm mr-2"
                                                                            >
                                                                                <img
                                                                                    className="avatar-img rounded-circle"
                                                                                    src="assets/img/doctors/doctor-thumb-02.jpg"
                                                                                    alt="User Image"
                                                                                />
                                                                            </a>
                                                                            <a href="doctor-profile.html">
                                                                                Dr. Sandhya Chinala <span>Psychiatrist</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        12 Nov 2019{" "}
                                                                        <span className="d-block text-info">8.00 PM</span>
                                                                    </td>
                                                                    <td>12 Nov 2019</td>
                                                                    <td>Richard@gmail.com</td>
                                                                    <td>54656251</td>
                                                                    <td>
                                                                        <span className="badge badge-pill bg-success-light">
                                                                            Finished
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a
                                                                                href="doctor-profile.html"
                                                                                className="avatar avatar-sm mr-2"
                                                                            >
                                                                                <img
                                                                                    className="avatar-img rounded-circle"
                                                                                    src="assets/img/doctors/doctor-thumb-02.jpg"
                                                                                    alt="User Image"
                                                                                />
                                                                            </a>
                                                                            <a href="doctor-profile.html">
                                                                                Dr. Sandhya Chinala <span>Psychiatrist</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        11 Nov 2019{" "}
                                                                        <span className="d-block text-info">11.00 AM</span>
                                                                    </td>
                                                                    <td>10 Nov 2019</td>
                                                                    <td>Richard@gmail.com</td>
                                                                    <td>54656251</td>
                                                                    <td>
                                                                        <span className="badge badge-pill bg-danger-light">
                                                                            Cancelled
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a
                                                                                href="doctor-profile.html"
                                                                                className="avatar avatar-sm mr-2"
                                                                            >
                                                                                <img
                                                                                    className="avatar-img rounded-circle"
                                                                                    src="assets/img/doctors/doctor-thumb-02.jpg"
                                                                                    alt="User Image"
                                                                                />
                                                                            </a>
                                                                            <a href="doctor-profile.html">
                                                                                Dr. Sandhya Chinala <span>Psychiatrist</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        10 Nov 2019{" "}
                                                                        <span className="d-block text-info">3.00 PM</span>
                                                                    </td>
                                                                    <td>10 Nov 2019</td>
                                                                    <td>Richard@gmail.com</td>
                                                                    <td>54656251</td>
                                                                    <td>
                                                                        <span className="badge badge-pill bg-warning-light">
                                                                            Pending
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a
                                                                                href="doctor-profile.html"
                                                                                className="avatar avatar-sm mr-2"
                                                                            >
                                                                                <img
                                                                                    className="avatar-img rounded-circle"
                                                                                    src="assets/img/doctors/doctor-thumb-02.jpg"
                                                                                    alt="User Image"
                                                                                />
                                                                            </a>
                                                                            <a href="doctor-profile.html">
                                                                                Dr. Sandhya Chinala <span>Psychiatrist</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        9 Nov 2019{" "}
                                                                        <span className="d-block text-info">7.00 PM</span>
                                                                    </td>
                                                                    <td>8 Nov 2019</td>
                                                                    <td>Richard@gmail.com</td>
                                                                    <td>54656251</td>
                                                                    <td>
                                                                        <span className="badge badge-pill bg-success-light">
                                                                            Finished
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a
                                                                                href="doctor-profile.html"
                                                                                className="avatar avatar-sm mr-2"
                                                                            >
                                                                                <img
                                                                                    className="avatar-img rounded-circle"
                                                                                    src="assets/img/doctors/doctor-thumb-02.jpg"
                                                                                    alt="User Image"
                                                                                />
                                                                            </a>
                                                                            <a href="doctor-profile.html">
                                                                                Dr. Sandhya Chinala <span>Psychiatrist</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        8 Nov 2019{" "}
                                                                        <span className="d-block text-info">9.00 AM</span>
                                                                    </td>
                                                                    <td>6 Nov 2019</td>
                                                                    <td>Richard@gmail.com</td>
                                                                    <td>54656251</td>
                                                                    <td>
                                                                        <span className="badge badge-pill bg-danger-light">
                                                                            Cancelled
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a
                                                                                href="doctor-profile.html"
                                                                                className="avatar avatar-sm mr-2"
                                                                            >
                                                                                <img
                                                                                    className="avatar-img rounded-circle"
                                                                                    src="assets/img/doctors/doctor-thumb-02.jpg"
                                                                                    alt="User Image"
                                                                                />
                                                                            </a>
                                                                            <a href="doctor-profile.html">
                                                                                Dr. Sandhya Chinala <span>Psychiatrist</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        8 Nov 2019{" "}
                                                                        <span className="d-block text-info">6.00 PM</span>
                                                                    </td>
                                                                    <td>6 Nov 2019</td>
                                                                    <td>Richard@gmail.com</td>
                                                                    <td>54656251</td>
                                                                    <td>
                                                                        <span className="badge badge-pill bg-success-light">
                                                                            Finished
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a
                                                                                href="doctor-profile.html"
                                                                                className="avatar avatar-sm mr-2"
                                                                            >
                                                                                <img
                                                                                    className="avatar-img rounded-circle"
                                                                                    src="assets/img/doctors/doctor-thumb-02.jpg"
                                                                                    alt="User Image"
                                                                                />
                                                                            </a>
                                                                            <a href="doctor-profile.html">
                                                                                Dr. Sandhya Chinala <span>Psychiatrist</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        7 Nov 2019{" "}
                                                                        <span className="d-block text-info">9.00 PM</span>
                                                                    </td>
                                                                    <td>7 Nov 2019</td>
                                                                    <td>Richard@gmail.com</td>
                                                                    <td>54656251</td>
                                                                    <td>
                                                                        <span className="badge badge-pill bg-success-light">
                                                                            Finished
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a
                                                                                href="doctor-profile.html"
                                                                                className="avatar avatar-sm mr-2"
                                                                            >
                                                                                <img
                                                                                    className="avatar-img rounded-circle"
                                                                                    src="assets/img/doctors/doctor-thumb-02.jpg"
                                                                                    alt="User Image"
                                                                                />
                                                                            </a>
                                                                            <a href="doctor-profile.html">
                                                                                Dr. Sandhya Chinala <span>Psychiatrist</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        6 Nov 2019{" "}
                                                                        <span className="d-block text-info">8.00 PM</span>
                                                                    </td>
                                                                    <td>4 Nov 2019</td>
                                                                    <td>Richard@gmail.com</td>
                                                                    <td>54656251</td>
                                                                    <td>
                                                                        <span className="badge badge-pill bg-success-light">
                                                                            Finished
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a
                                                                                href="doctor-profile.html"
                                                                                className="avatar avatar-sm mr-2"
                                                                            >
                                                                                <img
                                                                                    className="avatar-img rounded-circle"
                                                                                    src="assets/img/doctors/doctor-thumb-02.jpg"
                                                                                    alt="User Image"
                                                                                />
                                                                            </a>
                                                                            <a href="doctor-profile.html">
                                                                                Dr. Sandhya Chinala <span>Psychiatrist</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>
                                                                        5 Nov 2019{" "}
                                                                        <span className="d-block text-info">5.00 PM</span>
                                                                    </td>
                                                                    <td>1 Nov 2019</td>
                                                                    <td>Richard@gmail.com</td>
                                                                    <td>54656251</td>
                                                                    <td>
                                                                        <span className="badge badge-pill bg-success-light">
                                                                            Finished
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </>
    )
}

export default PatientAppointments