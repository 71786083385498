import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import feather from 'feather-icons';
import Header from '../Partials/Header/Header';
import Sidebar from '../Partials/Sidebar/Sidebar';


const MyAppointments = () => {

    
    return (
        // <div main id="main-wrapper" className="main-wrapper ">
        <>
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                    My Appointments
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">My Appointments</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className="container-fluid ">
                    <div className='row'>
                        <Sidebar />
                        <div className="col-md-7 col-lg-8 col-xl-9">

                            <div className="appointments">
                                {/* Appointment List */}
                                <div class="col-3 d-flex justify-content-end mb-3">
                                    <select class="custom-select">
                                        <option value="">Select Appointment</option>
                                        <option value="pending">Pending Appointment</option>
                                        <option value="accepted">Accepted Appointment</option>
                                        <option value="finished">Finished Appointment</option>
                                        <option value="today">Today's Appointment</option>
                                    </select>
                                </div>
                                <div className="appointment-list">
                                    <div className="profile-info-widget">
                                        <div className="profile-det-info">
                                            <h4>
                                                <span className='badge badge-warning-soft  '>Pending</span>
                                            </h4>
                                            <h3>
                                                <a href="patient-profile.html">Richard Wilson</a>
                                            </h3>
                                            <div className="patient-details">
                                                <h5>
                                                    <i className="far fa-clock" /> 14 Nov 2019, 10.00 AM
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-envelope" /> richard@example.com
                                                </h5>
                                                <h5 className="">
                                                    <i className="fas fa-phone" /> +1 923 782 4575
                                                </h5>
                                                <h5 className="mb-0">
                                                    <i className="fas fa-list" /> ...
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appointment-action">
                                        <Link
                                            to="/appointmentPending"
                                            className="btn btn-sm bg-info-light"
                                            data-toggle="modal"
                                            data-target="#appt_details"
                                        >
                                            <i className="far fa-eye" /> View
                                        </Link>

                                    </div>
                                </div>
                                {/* /Appointment List */}
                                {/* Appointment List */}
                                <div className="appointment-list">
                                    <div className="profile-info-widget">
                                        <div className="profile-det-info">
                                            <h4>
                                                <span className='badge badge-info-soft '>Accepted</span>
                                            </h4>
                                            <h3>
                                                <a href="patient-profile.html">Charlene Reed </a>
                                            </h3>
                                            <div className="patient-details">
                                                <h5>
                                                    <i className="far fa-clock" /> 12 Nov 2019, 5.00 PM
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-map-marker-alt" /> North Carolina, United
                                                    States
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-envelope" /> charlenereed@example.com
                                                </h5>
                                                <h5 className="">
                                                    <i className="fas fa-phone" /> +1 828 632 9170
                                                </h5>
                                                <h5 className="mb-0">
                                                    <i className="fas fa-list" /> ...
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appointment-action">
                                        <Link
                                            to="/appointmentAccepted"
                                            className="btn btn-sm bg-info-light"
                                            data-toggle="modal"
                                            data-target="#appt_details"
                                        >
                                            <i className="far fa-eye" /> View
                                        </Link>

                                    </div>
                                </div>
                                {/* /Appointment List */}
                                {/* Appointment List */}
                                <div className="appointment-list">
                                    <div className="profile-info-widget">
                                        <div className="profile-det-info">
                                            <h4>
                                                <span className='badge badge-success-soft '>Finished</span>
                                            </h4>
                                            <h3>
                                                <a href="patient-profile.html">Travis Trimble</a>
                                            </h3>
                                            <div className="patient-details">
                                                <h5>
                                                    <i className="far fa-clock" /> 11 Nov 2019, 8.00 PM
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-map-marker-alt" /> Maine, United States
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-envelope" /> travistrimble@example.com
                                                </h5>
                                                <h5 className="">
                                                    <i className="fas fa-phone" /> +1 207 729 9974
                                                </h5>
                                                <h5 className="mb-0">
                                                    <i className="fas fa-list" /> ...
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appointment-action">
                                        <Link
                                            to="/appointmentFinished"
                                            className="btn btn-sm bg-info-light"
                                            data-toggle="modal"
                                            data-target="#appt_details"
                                        >
                                            <i className="far fa-eye" /> View
                                        </Link>

                                    </div>
                                </div>
                                {/* /Appointment List */}
                                {/* Appointment List */}
                                <div className="appointment-list">
                                    <div className="profile-info-widget">
                                        <div className="profile-det-info">
                                            <h4>
                                                <span className='badge badge-warning-soft '>Pending</span>
                                            </h4>
                                            <h3>
                                                <a href="patient-profile.html">Carl Kelly</a>
                                            </h3>
                                            <div className="patient-details">
                                                <h5>
                                                    <i className="far fa-clock" /> 9 Nov 2019, 9.00 AM
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-envelope" /> carlkelly@example.com
                                                </h5>
                                                <h5 className="">
                                                    <i className="fas fa-phone" /> +1 260 724 7769
                                                </h5>
                                                <h5 className="mb-0">
                                                    <i className="fas fa-list" /> ...
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appointment-action">
                                        <Link
                                            to="/appointmentPending"
                                            className="btn btn-sm bg-info-light"
                                            data-toggle="modal"
                                            data-target="#appt_details"
                                        >
                                            <i className="far fa-eye" /> View
                                        </Link>

                                    </div>
                                </div>
                                {/* /Appointment List */}
                                {/* Appointment List */}
                                <div className="appointment-list">
                                    <div className="profile-info-widget">
                                        <div className="profile-det-info">
                                            <h4>
                                                <span className='badge badge-warning-soft '>Pending</span>
                                            </h4>
                                            <h3>
                                                <a href="patient-profile.html">Michelle Fairfax</a>
                                            </h3>
                                            <div className="patient-details">
                                                <h5>
                                                    <i className="far fa-clock" /> 9 Nov 2019, 1.00 PM
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-map-marker-alt" /> Indiana, United States
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-envelope" /> michellefairfax@example.com
                                                </h5>
                                                <h5 className="">
                                                    <i className="fas fa-phone" /> +1 504 368 6874
                                                </h5>
                                                <h5 className="mb-0">
                                                    <i className="fas fa-list" /> ...
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appointment-action">
                                        <Link
                                            to="/appointmentPending"
                                            className="btn btn-sm bg-info-light"
                                            data-toggle="modal"
                                            data-target="#appt_details"
                                        >
                                            <i className="far fa-eye" /> View
                                        </Link>

                                    </div>
                                </div>
                                {/* /Appointment List */}
                                {/* Appointment List */}
                                <div className="appointment-list">
                                    <div className="profile-info-widget">
                                        <div className="profile-det-info">
                                            <h4>
                                                <span className='badge badge-info-soft '>Accepted</span>
                                            </h4>
                                            <h3>
                                                <a href="patient-profile.html">Gina Moore</a>
                                            </h3>
                                            <div className="patient-details">
                                                <h5>
                                                    <i className="far fa-clock" /> 8 Nov 2019, 3.00 PM
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-envelope" /> ginamoore@example.com
                                                </h5>
                                                <h5 className="">
                                                    <i className="fas fa-phone" /> +1 954 820 7887
                                                </h5>
                                                <h5 className="mb-0">
                                                    <i className="fas fa-list" /> ...
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appointment-action">
                                        <Link
                                            to="/appointmentAccepted"
                                            className="btn btn-sm bg-info-light"
                                            data-toggle="modal"
                                            data-target="#appt_details"
                                        >
                                            <i className="far fa-eye" /> View
                                        </Link>

                                    </div>
                                </div>
                                {/* /Appointment List */}
                                {/* Appointment List */}
                                <div className="appointment-list">
                                    <div className="profile-info-widget">
                                        <div className="profile-det-info">
                                            <h4>
                                                <span className='badge badge-success-soft '>Finished</span>
                                            </h4>
                                            <h3>
                                                <a href="patient-profile.html">Elsie Gilley</a>
                                            </h3>
                                            <div className="patient-details">
                                                <h5>
                                                    <i className="far fa-clock" /> 6 Nov 2019, 9.00 AM
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-map-marker-alt" /> Kentucky, United States
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-envelope" /> elsiegilley@example.com
                                                </h5>
                                                <h5 className="">
                                                    <i className="fas fa-phone" /> +1 315 384 4562
                                                </h5>
                                                <h5 className="mb-0">
                                                    <i className="fas fa-list" /> ...
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appointment-action">
                                        <Link
                                            to="/appointmentFinished"
                                            className="btn btn-sm bg-info-light"
                                            data-toggle="modal"
                                            data-target="#appt_details"
                                        >
                                            <i className="far fa-eye" /> View
                                        </Link>

                                    </div>
                                </div>
                                {/* /Appointment List */}
                                {/* Appointment List */}
                                <div className="appointment-list">
                                    <div className="profile-info-widget">
                                        <div className="profile-det-info">
                                            <h4>
                                                <span className='badge badge-success-soft '>Finished</span>
                                            </h4>
                                            <h3>
                                                <a href="patient-profile.html">Joan Gardner</a>
                                            </h3>
                                            <div className="patient-details">
                                                <h5>
                                                    <i className="far fa-clock" /> 5 Nov 2019, 12.00 PM
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-envelope" /> joangardner@example.com
                                                </h5>
                                                <h5 className="">
                                                    <i className="fas fa-phone" /> +1 707 2202 603
                                                </h5>
                                                <h5 className="mb-0">
                                                    <i className="fas fa-list" /> ...
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appointment-action">
                                        <Link
                                            to="/appointmentFinished"
                                            className="btn btn-sm bg-info-light"
                                            data-toggle="modal"
                                            data-target="#appt_details"
                                        >
                                            <i className="far fa-eye" /> View
                                        </Link>

                                    </div>
                                </div>
                                {/* /Appointment List */}
                                {/* Appointment List */}
                                <div className="appointment-list">
                                    <div className="profile-info-widget">
                                        <div className="profile-det-info">
                                            <h4>
                                                <span className='badge badge-success-soft '>Finished</span>
                                            </h4>
                                            <h3>
                                                <a href="patient-profile.html">Daniel Griffing</a>
                                            </h3>
                                            <div className="patient-details">
                                                <h5>
                                                    <i className="far fa-clock" /> 5 Nov 2019, 7.00 PM
                                                </h5>
                                                <h5>
                                                    <i className="fas fa-envelope" /> danielgriffing@example.com
                                                </h5>
                                                <h5 className="">
                                                    <i className="fas fa-phone" /> +1 973 773 9497
                                                </h5>
                                                <h5 className="mb-0">
                                                    <i className="fas fa-list" /> ...
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appointment-action">
                                        <Link
                                            to="/appointmentFinished"
                                            className="btn btn-sm bg-info-light"
                                            data-toggle="modal"
                                            data-target="#appt_details"
                                        >
                                            <i className="far fa-eye" /> View
                                        </Link>

                                    </div>
                                </div>

                                {/* /Appointment List */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default MyAppointments