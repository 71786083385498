import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Partials/Header/Header'
import Sidebar from '../Partials/Sidebar/Sidebar'

const EditProfile = () => {
    return (
        <>
               <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                    My Appointments
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">My Appointments</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className="container-fluid ">
                    <div className='row'>
                        <Sidebar />
                        <div className="col-md-7 col-lg-8 col-xl-9">
                                    <div className="container">
                                        <div className="main-body">
                                            {/* /Breadcrumb */}
                                            <div className="row gutters-sm">
                                                <div className="col-md-12">
                                                    <div className="card mb-3">
                                                        <div className='card-body'>
                                                            <div className="profile-container">
                                                                <div className="profile-header d-flex justify-content-center">
                                                                    <h5 className='title'>Profile Update</h5>
                                                                    {/* <p>Manage your profile details and password</p> */}
                                                                </div>
                                                                <form className='' id="profie_update">
                                                                    {/* Name Field */}
                                                                    <div className="mb-4">
                                                                        <label htmlFor="name" className="form-label">
                                                                            Name
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control edit_input"
                                                                            id="name"
                                                                            value="Sandhya Chinala"
                                                                            placeholder="Enter your name"
                                                                            required=""
                                                                        />
                                                                    </div>
                                                                    {/* Email Field */}
                                                                    <div className="mb-4">
                                                                        <label htmlFor="email" className="form-label">
                                                                            Email
                                                                        </label>
                                                                        <input
                                                                            type="email"
                                                                            className="form-control edit_input"
                                                                            id="email"
                                                                            value="sandhya@gmail.com"
                                                                            placeholder="Enter your email"
                                                                            required=""
                                                                        />
                                                                    </div>
                                                                    {/* Password Field */}
                                                                    <div className="mb-4">
                                                                        <label htmlFor="password" className="form-label">
                                                                            Password
                                                                        </label>
                                                                        <input
                                                                            type="password"
                                                                            className="form-control edit_input"
                                                                            id="password"
                                                                            value="123456"
                                                                            placeholder="Enter your password"
                                                                            required=""
                                                                        />
                                                                    </div>
                                                                    {/* Change Password Section */}
                                                                    <hr />
                                                                    <h4 className="mt-4 mb-5">Change Password</h4>
                                                                    {/* Current Password */}
                                                                    <div className="mb-4">
                                                                        <label htmlFor="currentPassword" className="form-label">
                                                                            Current Password
                                                                        </label>
                                                                        <input
                                                                            type="password"
                                                                            className="form-control edit_input"
                                                                            id="currentPassword"
                                                                            placeholder="Enter current password"
                                                                            required=""
                                                                        />
                                                                    </div>
                                                                    {/* New Password */}
                                                                    <div className="mb-4">
                                                                        <label htmlFor="newPassword" className="form-label">
                                                                            New Password
                                                                        </label>
                                                                        <input
                                                                            type="password"
                                                                            className="form-control edit_input"
                                                                            id="newPassword"
                                                                            placeholder="Enter new password"
                                                                            required=""
                                                                        />
                                                                    </div>
                                                                    {/* Confirm New Password */}
                                                                    <div className="mb-4">
                                                                        <label htmlFor="confirmNewPassword" className="form-label">
                                                                            Confirm New Password
                                                                        </label>
                                                                        <input
                                                                            type="password"
                                                                            className="form-control edit_input"
                                                                            id="confirmNewPassword"
                                                                            placeholder="Confirm new password"
                                                                            required=""
                                                                        />
                                                                    </div>
                                                                    {/* Save Changes Button */}
                                                                    <button type="submit" className="btn btn-primary w-100">
                                                                        Save Changes
                                                                    </button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </>

    )
}

export default EditProfile