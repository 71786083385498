import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from '../Partials/Header/Header'


const PrivateRoute = ({ userDetail, onProjectChange, project }) => {
  const navigate = useNavigate();

  const authorization = localStorage.getItem("accesstoken");

  const location = useLocation();

  const logCurrentOutlet = () => {
    console.log("Current Outlet: ", window.location.pathname);
  };

  const showHeader = location.pathname !== "/login" && location.pathname !== "/"


  return (
    <>
      {/* < onProjectChange={onProjectChange} projectId={ project} /> */}
      {/* <Header /> */}
      {showHeader && <Header />}
        {authorization ? (
          <Outlet onNavigate={logCurrentOutlet} />
        ) : (
          <Navigate to="/" />
        )}

    </>
  );
};

export default PrivateRoute;