import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../Partials/Header/Header'
import Sidebar from '../Partials/Sidebar/Sidebar'

const Chat = () => {

    const navigate = useNavigate()
    const [showText, setShowText] = useState(false)
    const [isChatSlide, setIsChatSlide] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 991) {
          setIsChatSlide(false); // Remove 'chat-slide' on larger screens
        }
      };
  
      // Attach the resize listener
      window.addEventListener('resize', handleResize);
  
      // Cleanup listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const handleUserClick = (e) => {
        e.preventDefault()
      if (window.innerWidth <= 991) {
        setIsChatSlide(true); // Add 'chat-slide' on smaller screens
      }
    };
  
    const handleBackClick = () => {
      if (window.innerWidth <= 991) {
        setIsChatSlide(false); // Remove 'chat-slide' on smaller screens
      }
    };
  
    
    const patient = localStorage.getItem('patients')
    console.log(patient)

    useEffect(() => {
        if (patient === 1) {
            setShowText(true)
        } else {
            setShowText(false)

        }

    }, [])

    return (
        <>
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Messages
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Messages</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                        <div className={`chat-window ${isChatSlide ? 'chat-slide' : ''}`}>
                        {/* Chat Left */}
                                <div className="chat-cont-left">
                                    <div className="chat-header">
                                        <span>Chats</span>
                                        <a href="javascript:void(0)" className="chat-compose">
                                            <i className="material-icons">control_point</i>
                                        </a>
                                    </div>
                                    <form className="chat-search">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <i className="fas fa-search" />
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search"
                                            />
                                        </div>
                                    </form>
                                    <div className="chat-users-list">
                                        <div className="chat-scroll">
                                            <a  className="media" onClick={handleUserClick}>
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-away">
                                                        <img
                                                            src="assets/img/patients/patient.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div>
                                                        <div className="user-name">Richard Wilson</div>
                                                        <div className="user-last-chat">Hey, How are you?</div>
                                                    </div>
                                                    <div>
                                                        <div className="last-chat-time block">2 min</div>
                                                        <div className="badge badge-success badge-pill">15</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a
                                                className="media read-chat active"
                                                onClick={handleUserClick}
                                            >
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-online">
                                                        <img
                                                            src="assets/img/patients/patient1.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div>
                                                        <div className="user-name">Charlene Reed</div>
                                                        <div className="user-last-chat">I'll call you later </div>
                                                    </div>
                                                    <div>
                                                        <div className="last-chat-time block">8:01 PM</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="media" onClick={handleUserClick}>
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-away">
                                                        <img
                                                            src="assets/img/patients/patient2.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div>
                                                        <div className="user-name">Travis Trimble </div>
                                                        <div className="user-last-chat">
                                                            Give me a full explanation about our project
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="last-chat-time block">7:30 PM</div>
                                                        <div className="badge badge-success badge-pill">3</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="media read-chat" onClick={handleUserClick}>
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-online">
                                                        <img
                                                            src="assets/img/patients/patient3.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div>
                                                        <div className="user-name">Carl Kelly</div>
                                                        <div className="user-last-chat">
                                                            That's very good UI design
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="last-chat-time block">6:59 PM</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="media read-chat" onClick={handleUserClick}>
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-offline">
                                                        <img
                                                            src="assets/img/patients/patient4.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div>
                                                        <div className="user-name">Michelle Fairfax</div>
                                                        <div className="user-last-chat">
                                                            Yesterday i completed the task
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="last-chat-time block">11:21 AM</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="media read-chat" onClick={handleUserClick}>
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-online">
                                                        <img
                                                            src="assets/img/patients/patient5.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div>
                                                        <div className="user-name">Gina Moore</div>
                                                        <div className="user-last-chat">
                                                            What is the major functionality?
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="last-chat-time block">10:05 AM</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="media read-chat" onClick={handleUserClick}>
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-away">
                                                        <img
                                                            src="assets/img/patients/patient6.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div>
                                                        <div className="user-name">Elsie Gilley</div>
                                                        <div className="user-last-chat">
                                                            This has allowed me to showcase not only my technical
                                                            skills
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="last-chat-time block">Yesterday</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="media read-chat">
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-offline">
                                                        <img
                                                            src="assets/img/patients/patient7.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div>
                                                        <div className="user-name">Joan Gardner</div>
                                                        <div className="user-last-chat">
                                                            Let's talk briefly in the evening.{" "}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="last-chat-time block">Sunday</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="media read-chat">
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-online">
                                                        <img
                                                            src="assets/img/patients/patient8.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div>
                                                        <div className="user-name">Daniel Griffing</div>
                                                        <div className="user-last-chat">
                                                            Do you have any collections? If so, what of?
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="last-chat-time block">Saturday</div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="media read-chat">
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-away">
                                                        <img
                                                            src="assets/img/patients/patient9.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div>
                                                        <div className="user-name">Walter Roberson</div>
                                                        <div className="user-last-chat">
                                                            Connect the two modules with in 1 day.
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="last-chat-time block">Friday</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* /Chat Left */}
                                {/* Chat Right */}
                                <div className="chat-cont-right">
                                    <div className="chat-header">
                                        <a
                                            id="back_user_list"
                                            href="javascript:void(0)"
                                            className="back-user-list"
                                            onClick={handleBackClick}
                                        >
                                            <i className="material-icons">chevron_left</i>
                                        </a>
                                        <div className="media">
                                            <div className="media-img-wrap">
                                                <div className="avatar avatar-online">
                                                    <img
                                                        src="assets/img/patients/patient.jpg"
                                                        alt="User Image"
                                                        className="avatar-img rounded-circle"
                                                    />
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div className="user-name">Richard Wilson</div>
                                                <div className="user-status">online</div>
                                            </div>
                                        </div>
                                        <div className="chat-options">
                                            {
                                                !patient ?

                                                    <a
                                                        href="javascript:void(0)"
                                                        data-toggle="modal"
                                                        data-target="#voice_call"
                                                    >
                                                        <i className="material-icons">local_phone</i>
                                                    </a>:
                                                    <Link to="/patientAppointments" className='mr-5'>
                                                        <span className='fs-3'>Appointment Details</span>
                                                        </Link>
                                            }
                                            {/* <a
                                                href="javascript:void(0)"
                                                data-toggle="modal"
                                                data-target="#video_call"
                                            >
                                                <i className="material-icons">videocam</i>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <i className="material-icons">more_vert</i>
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="chat-body">
                                        <div className="chat-scroll">
                                            <ul className="list-unstyled">
                                                <li className="media sent">
                                                    <div className="media-body">
                                                        <div className="msg-box">
                                                            <div>
                                                                <p>Hello. What can I do for you?</p>
                                                                <ul className="chat-msg-info">
                                                                    <li>
                                                                        <div className="chat-time">
                                                                            <span>8:30 AM</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="media received">
                                                    <div className="avatar">
                                                        <img
                                                            src="assets/img/patients/patient.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="msg-box">
                                                            <div>
                                                                <p>I'm just looking around.</p>
                                                                <p>Will you tell me something about yourself?</p>
                                                                <ul className="chat-msg-info">
                                                                    <li>
                                                                        <div className="chat-time">
                                                                            <span>8:35 AM</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="msg-box">
                                                            <div>
                                                                <p>Are you there? That time!</p>
                                                                <ul className="chat-msg-info">
                                                                    <li>
                                                                        <div className="chat-time">
                                                                            <span>8:40 AM</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="msg-box">
                                                            <div>
                                                                <div className="chat-msg-attachments">
                                                                    <div className="chat-attachment">
                                                                        <img
                                                                            src="assets/img/img-02.jpg"
                                                                            alt="Attachment"
                                                                        />
                                                                        <div className="chat-attach-caption">
                                                                            placeholder.jpg
                                                                        </div>
                                                                        <a href="#" className="chat-attach-download">
                                                                            <i className="fas fa-download" />
                                                                        </a>
                                                                    </div>
                                                                    <div className="chat-attachment">
                                                                        <img
                                                                            src="assets/img/img-03.jpg"
                                                                            alt="Attachment"
                                                                        />
                                                                        <div className="chat-attach-caption">
                                                                            placeholder.jpg
                                                                        </div>
                                                                        <a href="#" className="chat-attach-download">
                                                                            <i className="fas fa-download" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <ul className="chat-msg-info">
                                                                    <li>
                                                                        <div className="chat-time">
                                                                            <span>8:41 AM</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="media sent">
                                                    <div className="media-body">
                                                        <div className="msg-box">
                                                            <div>
                                                                <p>Where?</p>
                                                                <ul className="chat-msg-info">
                                                                    <li>
                                                                        <div className="chat-time">
                                                                            <span>8:42 AM</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="msg-box">
                                                            <div>
                                                                <p>
                                                                    OK, my name is Limingqiang. I like singing, playing
                                                                    basketballand so on.
                                                                </p>
                                                                <ul className="chat-msg-info">
                                                                    <li>
                                                                        <div className="chat-time">
                                                                            <span>8:42 AM</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="msg-box">
                                                            <div>
                                                                <div className="chat-msg-attachments">
                                                                    <div className="chat-attachment">
                                                                        <img
                                                                            src="assets/img/img-04.jpg"
                                                                            alt="Attachment"
                                                                        />
                                                                        <div className="chat-attach-caption">
                                                                            placeholder.jpg
                                                                        </div>
                                                                        <a href="#" className="chat-attach-download">
                                                                            <i className="fas fa-download" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <ul className="chat-msg-info">
                                                                    <li>
                                                                        <div className="chat-time">
                                                                            <span>8:50 AM</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="media received">
                                                    <div className="avatar">
                                                        <img
                                                            src="assets/img/patients/patient.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="msg-box">
                                                            <div>
                                                                <p>You wait for notice.</p>
                                                                <p>Consectetuorem ipsum dolor sit?</p>
                                                                <p>Ok?</p>
                                                                <ul className="chat-msg-info">
                                                                    <li>
                                                                        <div className="chat-time">
                                                                            <span>8:55 PM</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="chat-date">Today</li>
                                                <li className="media received">
                                                    <div className="avatar">
                                                        <img
                                                            src="assets/img/patients/patient.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="msg-box">
                                                            <div>
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                                    elit,
                                                                </p>
                                                                <ul className="chat-msg-info">
                                                                    <li>
                                                                        <div className="chat-time">
                                                                            <span>10:17 AM</span>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#">Edit</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="media sent">
                                                    <div className="media-body">
                                                        <div className="msg-box">
                                                            <div>
                                                                <p>Lorem ipsum dollar sit</p>
                                                                <div className="chat-msg-actions dropdown">
                                                                    <a
                                                                        href="#"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fe fe-elipsis-v" />
                                                                    </a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item" href="#">
                                                                            Delete
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <ul className="chat-msg-info">
                                                                    <li>
                                                                        <div className="chat-time">
                                                                            <span>10:19 AM</span>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#">Edit</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="media received">
                                                    <div className="avatar">
                                                        <img
                                                            src="assets/img/patients/patient.jpg"
                                                            alt="User Image"
                                                            className="avatar-img rounded-circle"
                                                        />
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="msg-box">
                                                            <div>
                                                                <div className="msg-typing">
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="chat-footer">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="btn-file btn">
                                                    <i className="fa fa-paperclip" />
                                                    <input type="file" />
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="input-msg-send form-control"
                                                placeholder="Type something"
                                            />
                                            <div className="input-group-append">
                                                <button type="button" className="btn msg-send-btn">
                                                    <i className="fab fa-telegram-plane" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /Chat Right */}
                            </div>
                        </div>
                    </div>
                    {/* /Row */}
                </div>
            </div>
        </>

    )
}

export default Chat