import React, { useEffect, useState } from 'react'
import Header from '../Partials/Header/Header'
import { db } from '../../firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';
import feather from 'feather-icons';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../Partials/Sidebar/Sidebar';
import Swal from 'sweetalert2';



const Dashboard = () => {
    const [totalAppointments, setTotalAppointments] = useState('')

    const appointmentsRef = collection(db, 'appointments');

    async function getTotalAppointments() {
        const snapshot = await getDocs(appointmentsRef);
        return snapshot.size;
    }

    async function updateDashboard() {
        const totalAppointments = await getTotalAppointments();
        setTotalAppointments(totalAppointments)
        // const pendingAppointments = await getPendingAppointmentsCount();
        // const acceptedAppointments = await getAcceptedAppointmentsCount();
        // const finishedAppointments = await getFinishedAppointmentsCount();
        // const todayAppointments = await getTodayAppointmentsCount();

        // console.log("Total Appointments:", totalAppointments);
        // console.log("Pending Appointments:", pendingAppointments);
        // console.log("Accepted Appointments:", acceptedAppointments);
        // console.log("Finished Appointments:", finishedAppointments);
        // console.log("Today's Appointments:", todayAppointments);

        // Update your dashboard UI with these values
    }


    const handleReject = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to reject this appointment!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Reject it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Rejected!",
                    text: "The appointment has been reject.",
                    icon: "success"
                });
            }
        });
    }

    const handleCancel = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to cancel this appointment!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, cancel it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Cancelled!",
                    text: "The appointment has been cancelled.",
                    icon: "success"
                });
            }
        });
    }


    return (
        <>
          
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="index-2.html">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Dashboard
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Dashboard</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className="container-fluid ">
                    <div className='row'>
                        <Sidebar />
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card dash-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="dash-widget dct-border-rht">
                                                        <div className="circle-bar circle-bar1">
                                                            <div className="circle-graph1" data-percent={75}>
                                                                <img
                                                                    src="assets/img/icon-01.png"
                                                                    className="img-fluid"
                                                                    alt="patient"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="dash-widget-info">
                                                            <h4>Total Patient</h4>
                                                            <h3>1500</h3>
                                                            <p className="text-muted">Till Today</p>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="dash-widget">
                                                        <div className="circle-bar circle-bar3">
                                                            <div className="circle-graph3" data-percent={50}>
                                                                <img
                                                                    src="assets/img/icon-03.png"
                                                                    className="img-fluid"
                                                                    alt="Patient"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="dash-widget-info">
                                                            <h5>Total  Appoinments</h5>
                                                            <h3>85</h3>
                                                            <p className="text-muted">06, Apr 2024</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="dash-widget dct-border-rht">
                                                        <div className="circle-bar circle-bar2">
                                                            <div className="circle-graph2" data-percent={65}>
                                                                <img
                                                                    src="assets/img/icon-03.png"
                                                                    className="img-fluid"
                                                                    alt="Patient"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="dash-widget-info">
                                                            <h5>Todays Appointments</h5>
                                                            <h3>16 </h3>
                                                            <p className="text-muted">06, Nov 2024</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card dash-card">
                                        <div className="card-body">
                                            <div className="row">
                                            <div className="col-md-12 col-lg-4">
                                                    <div className="dash-widget">
                                                        <div className="circle-bar circle-bar4">
                                                            <div className="circle-graph4" data-percent={50}>
                                                                <img
                                                                    src="assets/img/icon-03.png"
                                                                    className="img-fluid"
                                                                    alt="Patient"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="dash-widget-info">
                                                            <h5>Pending Appoinments</h5>
                                                            <h3>85</h3>
                                                            <p className="text-muted">06, Apr 2024</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="dash-widget dct-border-rht">
                                                        <div className="circle-bar circle-bar3">
                                                            <div className="circle-graph3" data-percent={75}>
                                                                <img
                                                                    src="assets/img/icon-03.png"
                                                                    className="img-fluid"
                                                                    alt="patient"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="dash-widget-info">
                                                            <h5>Accepted Appointments</h5>
                                                            <h3>15</h3>
                                                            <p className="text-muted">Till Today</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="dash-widget dct-border-rht">
                                                        <div className="circle-bar circle-bar5">
                                                            <div className="circle-graph5" data-percent={65}>
                                                                <img
                                                                    src="assets/img/icon-03.png"
                                                                    className="img-fluid"
                                                                    alt="Patient"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="dash-widget-info">
                                                            <h5>Finished Appoinment</h5>
                                                            <h3>160</h3>
                                                            <p className="text-muted">06, Nov 2024</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="mb-4">Patient Appoinment</h4>
                                    <div className="appointment-tab">
                                        {/* Appointment Tab */}
                                        <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active "
                                                    href="#upcoming-appointments"
                                                    data-toggle="tab"
                                                >
                                                    Upcoming
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#today-appointments" data-toggle="tab">
                                                    Today
                                                </a>
                                            </li>
                                        </ul>
                                        {/* /Appointment Tab */}
                                        <div className="tab-content">
                                            {/* Upcoming Appointment Tab */}
                                            <div className="tab-pane show active" id="upcoming-appointments">
                                                <div className="card card-table mb-0">
                                                    <div className="card-body">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover table-center mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Patient Name</th>
                                                                        <th>App Date</th>
                                                                        <th>Booking Date</th>
                                                                        <th className="">Email</th>
                                                                        <th className="text-center">Description</th>
                                                                        {/* <th className="">Status</th> */}
                                                                        <th />
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Richard Wilson <span>#PT0016</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            11 Nov 2024{" "}
                                                                            <span className="d-block text-info">10.00 AM</span>
                                                                        </td>
                                                                        <td>31/8/2024</td>
                                                                        <td>richard23@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentPending"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-success-light"
                                                                                >
                                                                                    <i className="fas fa-check" /> Accept
                                                                                </a>
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleReject}
                                                                                >
                                                                                    <i className="fas fa-times" /> Reject
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Charlene Reed <span>#PT0001</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            3 Nov 2024{" "}
                                                                            <span className="d-block text-info">11.00 AM</span>
                                                                        </td>
                                                                        <td>28/8/2024</td>
                                                                        <td>charlene@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentPending"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-success-light"
                                                                                >
                                                                                    <i className="fas fa-check" /> Accept
                                                                                </a>
                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleReject}
                                                                                >
                                                                                    <i className="fas fa-times" /> Reject
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Travis Trimble <span>#PT0002</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            1 Nov 2024{" "}
                                                                            <span className="d-block text-info">1.00 PM</span>
                                                                        </td>
                                                                        <td>28/10/2024</td>
                                                                        <td>travis29@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentPending"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-success-light"
                                                                                >
                                                                                    <i className="fas fa-check" /> Accept
                                                                                </a>
                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleReject}
                                                                                >
                                                                                    <i className="fas fa-times" /> Reject
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Carl Kelly <span>#PT0003</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            30 Oct 2024{" "}
                                                                            <span className="d-block text-info">9.00 AM</span>
                                                                        </td>
                                                                        <td>20/10/2024</td>
                                                                        <td>kelly@yahoo.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentPending"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-success-light"
                                                                                >
                                                                                    <i className="fas fa-check" /> Accept
                                                                                </a>
                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleReject}
                                                                                >
                                                                                    <i className="fas fa-times" /> Reject
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Michelle Fairfax <span>#PT0004</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            20 Oct 2024{" "}
                                                                            <span className="d-block text-info">6.00 PM</span>
                                                                        </td>
                                                                        <td>15/10/2024</td>
                                                                        <td>michelle@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentPending"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-success-light"
                                                                                >
                                                                                    <i className="fas fa-check" /> Accept
                                                                                </a>
                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleReject}
                                                                                >
                                                                                    <i className="fas fa-times" /> Reject
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Gina Moore <span>#PT0005</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            27 Oct 2024{" "}
                                                                            <span className="d-block text-info">8.00 AM</span>
                                                                        </td>
                                                                        <td>16/10/2024</td>
                                                                        <td>moore@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentPending"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-success-light"
                                                                                >
                                                                                    <i className="fas fa-check" /> Accept
                                                                                </a>
                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleReject}
                                                                                >
                                                                                    <i className="fas fa-times" /> Reject
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Upcoming Appointment Tab */}
                                            {/* Today Appointment Tab */}
                                            <div className="tab-pane" id="today-appointments">
                                                <div className="card card-table mb-0">
                                                    <div className="card-body">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover table-center mb-0">
                                                                <thead>
                                                                <tr>
                                                                        <th>Patient Name</th>
                                                                        <th>App Date</th>
                                                                        <th>Booking Date</th>
                                                                        <th className="">Email</th>
                                                                        <th className="text-center">Description</th>
                                                                        {/* <th className="">Status</th> */}
                                                                        <th />
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Elsie Gilley <span>#PT0006</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            14 Oct 2024{" "}
                                                                            <span className="d-block text-info">6.00 PM</span>
                                                                        </td>
                                                                        <td>1/10/24</td>
                                                                        <td>elsie@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentAccepted"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                    
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>

                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleCancel}
                                                                                    
                                                                                >
                                                                                    <i className="fas fa-times" /> Cancel
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Joan Gardner <span>#PT0006</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            14 Oct 2024{" "}
                                                                            <span className="d-block text-info">5.00 PM</span>
                                                                        </td>
                                                                        <td>2/10/24</td>
                                                                        <td>john@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentAccepted"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>

                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleCancel}
                                                                                >
                                                                                    <i className="fas fa-times" /> Cancel
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Daniel Griffing <span>#PT0007</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            14 Oct 2024{" "}
                                                                            <span className="d-block text-info">3.00 PM</span>
                                                                        </td>
                                                                        <td>2/10/24</td>
                                                                        <td>daniel@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentAccepted"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>

                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleCancel}
                                                                                >
                                                                                    <i className="fas fa-times" /> Cancel
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Walter Roberson <span>#PT0008</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            14 Oct 2024{" "}
                                                                            <span className="d-block text-info">1.00 PM</span>
                                                                        </td>
                                                                        <td>2/10/24</td>
                                                                        <td>walter@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentAccepted"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>

                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleCancel}
                                                                                >
                                                                                    <i className="fas fa-times" /> Cancel
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Robert Rhodes <span>#PT0010</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            14 Oct 2024{" "}
                                                                            <span className="d-block text-info">10.00 AM</span>
                                                                        </td>
                                                                        <td>2/10/24</td>
                                                                        <td>robert@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentAccepted"
                                                                                    className="btn btn-sm bg-info-light"

                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>

                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleCancel}
                                                                                >
                                                                                    <i className="fas fa-times" /> Cancel
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a href="patient-profile.html">
                                                                                    Harry Williams <span>#PT0011</span>
                                                                                </a>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            14 Oct 2024{" "}
                                                                            <span className="d-block text-info">11.00 AM</span>
                                                                        </td>
                                                                        <td>2/10/24</td>
                                                                        <td>harry@gmail.com</td>
                                                                        <td className="text-center">...</td>
                                                                        <td className="text-right">
                                                                            <div className="table-action">
                                                                                <Link
                                                                                    to="/appointmentAccepted"
                                                                                    className="btn btn-sm bg-info-light"
                                                                                >
                                                                                    <i className="far fa-eye" /> View
                                                                                </Link>
                                                                                <Link
                                                                                    href="javascript:void(0);"
                                                                                    className="btn btn-sm bg-danger-light"
                                                                                    onClick={handleCancel}
                                                                                >
                                                                                    <i className="fas fa-times" /> Cancel
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Today Appointment Tab */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard
