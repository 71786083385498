import React, { useEffect, useState } from 'react'
import { auth } from '../../../firebase/firebase';
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import Sidebar from '../Sidebar/Sidebar';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {

    const handleToggle = () => {
        const e = document.getElementById("nav-toggle");
        if (e) {
            document.getElementById("main-wrapper").classList.toggle("toggled");
        }
    };

    const location = useLocation()

    const LogoutHandler = () => {
        signOut(auth).then(() => {
            localStorage.removeItem('accesstoken')
            toast.success("User signed out successfully..", { position: 'top-center' })
            setTimeout(() => {
                window.location.href = "/"
            }, 500)
            console.log("User signed out successfully");
        }).catch((error) => {
            // An error happened.
            console.error("Error signing out:", error);
        });
    }

    const isActive = (path) => location.pathname === path;



    return (
        <>
            {/* <main id="main-wrapper" className="main-wrapper "> */}

            <header className="header">
                <nav className="navbar navbar-expand-lg header-nav">
                    <div className="navbar-header">
                        <a id="mobile_btn" href="javascript:void(0);">
                            <span className="bar-icon">
                                <span />
                                <span />
                                <span />
                            </span>
                        </a>
                        <Link to="/dashboard" className="navbar-brand logo">
                            <img src="/assets/img/logo.jpg" className="img-fluid" alt="Logo" />
                        </Link>
                    </div>
                    <div className="main-menu-wrapper">
                        <div className="menu-header">
                            <Link to="index-2.html" className="menu-logo">
                                <img src="/assets/img/logo.jpg" className="img-fluid" alt="Logo" />
                            </Link>
                            <a id="menu_close" className="menu-close" href="javascript:void(0);">
                                <i className="fas fa-times" />
                            </a>
                        </div>
                        <ul className="main-nav">
                            <li className={isActive("/dashboard") ? "active" : ""}>
                                <Link to="/dashboard">Home</Link>
                            </li>
                            <li className={isActive("/myappointments") ? "active has-submenu" : "has-submenu"}>
                                <Link to="/myappointments">
                                    My Appoinments
                                </Link>
                            </li>
                            <li className={isActive("/myavailablity") ? "active has-submenu" : "has-submenu"}>
                                <Link to="/myavailablity">
                                    Schedule Timings
                                </Link>
                            </li>
                            <li className={isActive("/chat") ? "active has-submenu" : "has-submenu"}>
                                <Link to="/chat">
                                    Message
                                </Link>
                            </li>
                            <li className={isActive("/mypatients") ? "active has-submenu" : "has-submenu"}>
                                <Link to="/mypatients">
                                    <span>My Patients</span>
                                </Link>
                            </li>
                            <li className='has-submenu'>                            
                                   <Link onClick={LogoutHandler}>
                                Logout
                            </Link>
                            </li>
                        </ul>
                    </div>
                    <ul className="nav header-navbar-rht">

                        {/* User Menu */}
                        <li className="nav-item dropdown has-arrow logged-item">
                            <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                                <span className="user-img">
                                    <img
                                        className="rounded-circle"
                                        src="/assets/img/doctor.png"
                                        width={31}
                                        alt="Dr sandhaya"
                                    />
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <div className="user-header">
                                    <div className="avatar avatar-sm">
                                        <img
                                            src="/assets/img/doctor.png"
                                            alt="Dr.Image"
                                            className="avatar-img rounded-circle"
                                        />
                                    </div>
                                    <div className="user-text">
                                        <h6>Dr. Sandhya Chinala</h6>
                                        <p className="text-muted mb-0">Psychiatrist, MD                                        </p>
                                    </div>
                                </div>
                                <Link className="dropdown-item" to="/dashboard">
                                    Dashboard
                                </Link>
                                <Link className="dropdown-item" to="/editprofile">
                                    Profile Settings
                                </Link>
                                <Link className="dropdown-item" onClick={LogoutHandler}>
                                    Logout
                                </Link>
                            </div>
                        </li>
                        {/* /User Menu */}
                    </ul>
                </nav>
            </header>






        </>

    )
}

export default Header