import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import feather from 'feather-icons';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import Sidebar from '../Partials/Sidebar/Sidebar';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// import 'bootstrap/dist/js/bootstrap.bundle.min';





const MyAvailability = () => {
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const handleClose = () => setShow(false);
    const handleCloseModal = () => setShowEdit(false);
    const handleShow = () => setShow(true);

    const handleSave = () => {
        // Handle save logic here
        console.log('Start Time:', startTime);
        console.log('End Time:', endTime);
        handleClose();
    };

    useEffect(() => {
        feather.replace();
    }, [])
    return (
        <>
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Schedule Timings
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Schedule Timings</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className="container-fluid ">
                    <div className='row'>
                        <Sidebar />
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Schedule Timings</h4>
                                            <div className="profile-box">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label>Timing Slot Duration</label>
                                                            <select className="select form-control">
                                                                <option>-</option>
                                                                <option>15 mins</option>
                                                                <option selected="selected">30 mins</option>
                                                                <option>45 mins</option>
                                                                <option>1 Hour</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="card schedule-widget mb-0">
                                                            {/* Schedule Header */}
                                                            <div className="schedule-header">
                                                                {/* Schedule Nav */}
                                                                <div className="schedule-nav">
                                                                    <ul className="nav nav-tabs nav-justified">
                                                                        <li className="nav-item">
                                                                            <a
                                                                                className="nav-link"
                                                                                data-toggle="tab"
                                                                                href="#slot_sunday"
                                                                            >
                                                                                Sunday
                                                                            </a>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <a
                                                                                className="nav-link active"
                                                                                data-toggle="tab"
                                                                                href="#slot_monday"
                                                                            >
                                                                                Monday
                                                                            </a>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <a
                                                                                className="nav-link"
                                                                                data-toggle="tab"
                                                                                href="#slot_tuesday"
                                                                            >
                                                                                Tuesday
                                                                            </a>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <a
                                                                                className="nav-link"
                                                                                data-toggle="tab"
                                                                                href="#slot_wednesday"
                                                                            >
                                                                                Wednesday
                                                                            </a>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <a
                                                                                className="nav-link"
                                                                                data-toggle="tab"
                                                                                href="#slot_thursday"
                                                                            >
                                                                                Thursday
                                                                            </a>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <a
                                                                                className="nav-link"
                                                                                data-toggle="tab"
                                                                                href="#slot_friday"
                                                                            >
                                                                                Friday
                                                                            </a>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <a
                                                                                className="nav-link"
                                                                                data-toggle="tab"
                                                                                href="#slot_saturday"
                                                                            >
                                                                                Saturday
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                {/* /Schedule Nav */}
                                                            </div>
                                                            {/* /Schedule Header */}
                                                            {/* Schedule Content */}
                                                            <div className="tab-content schedule-cont">
                                                                {/* Sunday Slot */}
                                                                <div id="slot_sunday" className="tab-pane fade">
                                                                    <h4 className="card-title d-flex justify-content-between">
                                                                        <span>Time Slots</span>
                                                                        <a
                                                                            className="edit-link"
                                                                            data-toggle="modal"
                                                                            href="#add_time_slot"
                                                                        >
                                                                            <i className="fa fa-plus-circle" /> Add Slot
                                                                        </a>
                                                                    </h4>
                                                                    <p className="text-muted mb-0">Not Available</p>
                                                                </div>
                                                                {/* /Sunday Slot */}
                                                                {/* Monday Slot */}
                                                                <div id="slot_monday" className="tab-pane fade show active">
                                                                    <h4 className="card-title d-flex justify-content-between ">
                                                                        <span className=''>Time Slots</span>
                                                                        <div className='d-flex'>
                                                                            <a
                                                                                className="edit-link mr-4"
                                                                                data-toggle="modal"
                                                                                // href="#edit_time_slot"
                                                                                onClick={() => setShowEdit(true)}
                                                                            >
                                                                                <i className="fa fa-edit mr-1" />
                                                                                Edit
                                                                            </a>
                                                                            <a
                                                                                className="add-btn"
                                                                                data-toggle="modal"
                                                                                // href="#add_time_slot"
                                                                                onClick={() => setShow(true)}
                                                                            >
                                                                                <i className="fa fa-plus-circle" /> Add Slot
                                                                            </a>
                                                                        </div>
                                                                    </h4>
                                                                    {/* Slot List */}
                                                                    <div className="doc-times">
                                                                        <div className="doc-slot-list">
                                                                            8:00 pm - 11:30 pm
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                className="delete_schedule"
                                                                            >
                                                                                <i className="fa fa-times" />
                                                                            </a>
                                                                        </div>
                                                                        <div className="doc-slot-list">
                                                                            11:30 pm - 1:30 pm
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                className="delete_schedule"
                                                                            >
                                                                                <i className="fa fa-times" />
                                                                            </a>
                                                                        </div>
                                                                        <div className="doc-slot-list">
                                                                            3:00 pm - 5:00 pm
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                className="delete_schedule"
                                                                            >
                                                                                <i className="fa fa-times" />
                                                                            </a>
                                                                        </div>
                                                                        <div className="doc-slot-list">
                                                                            6:00 pm - 11:00 pm
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                className="delete_schedule"
                                                                            >
                                                                                <i className="fa fa-times" />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    {/* /Slot List */}
                                                                </div>
                                                                {/* /Monday Slot */}
                                                                {/* Tuesday Slot */}
                                                                <div id="slot_tuesday" className="tab-pane fade">
                                                                    <h4 className="card-title d-flex justify-content-between">
                                                                        <span>Time Slots</span>
                                                                        <a
                                                                            className="edit-link"
                                                                            data-toggle="modal"
                                                                            href="#add_time_slot"
                                                                        >
                                                                            <i className="fa fa-plus-circle" /> Add Slot
                                                                        </a>
                                                                    </h4>
                                                                    <p className="text-muted mb-0">Not Available</p>
                                                                </div>
                                                                {/* /Tuesday Slot */}
                                                                {/* Wednesday Slot */}
                                                                <div id="slot_wednesday" className="tab-pane fade">
                                                                    <h4 className="card-title d-flex justify-content-between">
                                                                        <span>Time Slots</span>
                                                                        <a
                                                                            className="edit-link"
                                                                            data-toggle="modal"
                                                                            href="#add_time_slot"
                                                                        >
                                                                            <i className="fa fa-plus-circle" /> Add Slot
                                                                        </a>
                                                                    </h4>
                                                                    <p className="text-muted mb-0">Not Available</p>
                                                                </div>
                                                                {/* /Wednesday Slot */}
                                                                {/* Thursday Slot */}
                                                                <div id="slot_thursday" className="tab-pane fade">
                                                                    <h4 className="card-title d-flex justify-content-between">
                                                                        <span>Time Slots</span>
                                                                        <a
                                                                            className="edit-link"
                                                                            data-toggle="modal"
                                                                            href="#add_time_slot"
                                                                        >
                                                                            <i className="fa fa-plus-circle" /> Add Slot
                                                                        </a>
                                                                    </h4>
                                                                    <p className="text-muted mb-0">Not Available</p>
                                                                </div>
                                                                {/* /Thursday Slot */}
                                                                {/* Friday Slot */}
                                                                <div id="slot_friday" className="tab-pane fade">
                                                                    <h4 className="card-title d-flex justify-content-between">
                                                                        <span>Time Slots</span>
                                                                        <a
                                                                            className="edit-link"
                                                                            data-toggle="modal"
                                                                            href="#add_time_slot"
                                                                        >
                                                                            <i className="fa fa-plus-circle" /> Add Slot
                                                                        </a>
                                                                    </h4>
                                                                    <p className="text-muted mb-0">Not Available</p>
                                                                </div>
                                                                {/* /Friday Slot */}
                                                                {/* Saturday Slot */}
                                                                <div id="slot_saturday" className="tab-pane fade">
                                                                    <h4 className="card-title d-flex justify-content-between">
                                                                        <span>Time Slots</span>
                                                                        <a
                                                                            className="edit-link"
                                                                            data-toggle="modal"
                                                                            href="#add_time_slot"
                                                                        >
                                                                            <i className="fa fa-plus-circle" /> Add Slot
                                                                        </a>
                                                                    </h4>
                                                                    <p className="text-muted mb-0">Not Available</p>
                                                                </div>
                                                                {/* /Saturday Slot */}
                                                            </div>
                                                            {/* /Schedule Content */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


            <Modal show={showEdit} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Start/End Time</Modal.Title>
            </Modal.Header>
            <Modal.Body modal>
                <h5>Time Slots</h5>
                <Table  bordered hover>
                    <thead>
                        <tr>
                            <th>Slot</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Slot 1</td>
                            <td><input type='time' value={'08:00'}/></td>
                            <td><input type='time' value={'09:00'}/></td>

                        </tr>
                        <tr>
                            <td>Slot 2</td>
                            <td><input type='time' value={'09:00'}/></td>
                            <td><input type='time' value={'10:00'}/></td>
                        </tr>
                        <tr>
                            <td>Slot 3</td>
                            <td><input type='time' value={'10:00'}/></td>
                            <td><input type='time' value={'11:00'}/></td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {setShowEdit(false)}}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => {setShowEdit(false)}}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>


            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Start and End Time</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control
                                type="time"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>



        </>
    )
}

export default MyAvailability