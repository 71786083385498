import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from '../../../firebase/firebase';
import { signOut } from 'firebase/auth';

const Sidebar = () => {
    const location = useLocation(); // Get current route

    const e = document.getElementById("nav-toggle");
    if (e) {
        e.addEventListener("click", function (e) {
            e.preventDefault();
            document.getElementById("main-wrapper").classList.toggle("toggled");
        });
    }

    const isActive = (path) => location.pathname === path;

    const LogoutHandler = () => {
        signOut(auth).then(() => {
            localStorage.removeItem('accesstoken')
            toast.success("User signed out successfully..", { position: 'top-center' })
            setTimeout(() => {
                window.location.href = "/"
            }, 500)
            console.log("User signed out successfully");
        }).catch((error) => {
            // An error happened.
            console.error("Error signing out:", error);
        });
    }






    return (
        <>


            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                {/* Profile Sidebar */}
                <div className="profile-sidebar">
                    <div className="widget-profile pro-widget-content">
                        <div className="profile-info-widget">
                            <a href="#" className="booking-doc-img">
                                <img src="/assets/img/doctor.png" alt="User Image" />
                            </a>
                            <div className="profile-det-info">
                                <h3>Dr. Sandhya Chinala</h3>
                                <div className="patient-details">
                                    <h5 className="mb-0">
                                        Psychiatrist, MD
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-widget">
                        <nav className="dashboard-menu">
                            <ul>
                                <li className={isActive("/dashboard") ? "active" : ""}>
                                    <Link to="/dashboard">
                                        <i className="fas fa-columns"></i>
                                        <span>Dashboard</span>
                                    </Link>
                                </li>
                                <li className={isActive("/myappointments") ? "active" : ""}>
                                    <Link to="/myappointments">
                                        <i className="fas fa-calendar-check"></i>
                                        <span>My Appointment</span>
                                    </Link>
                                </li>
                                <li className={isActive("/myavailablity") ? "active" : ""}>
                                    <Link to="/myavailablity">
                                        <i className="fas fa-hourglass-start"></i>
                                        <span>Schedule Timings</span>
                                    </Link>
                                </li>
                                <li className={isActive("/chat") ? "active" : ""}>
                                    <Link to="/chat" target='_blank'>
                                        <i className="fas fa-comments"></i>
                                        <span>Message</span>
                                    </Link>
                                </li>
                                <li className={isActive("/mypatients") ? "active" : ""}>
                                    <Link to="/mypatients">
                                        <i className="fas fa-user-injured"></i>
                                        <span>My Patients</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={LogoutHandler}>
                                        <i className="fas fa-sign-out-alt"></i>
                                        <span>Logout</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {/* /Profile Sidebar */}
            </div>

        </>



    )
}

export default Sidebar